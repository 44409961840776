export function usePartnerConfig() {
  const { t } = useI18n()
  const { query, path } = useRoute()
  const isMobile = useMobileByScreen()

  const isListPage = computed(() => path.includes('/attractions/list'))

  const listRoutePath = computed(() => `/attractions/list/${makeListRoute()}`)

  const commonDataStore = useCommonStore()
  const config = computed(() => {
    const { partnerInfo } = commonDataStore.commonData || {}

    return isListPage.value ? partnerInfo?.distStyle?.listPageStyle : partnerInfo?.distStyle?.indexPageStyle
  })

  const pageTitle = computed(() => query.campaign_id ? `${config.value?.title || t('key.home.partnerTitle')}` : t('key.home.title'))
  const subTitle = computed(() => query.campaign_id ? `${config.value?.subTitle || t('key.home.partnerSubTitle')}` : t('key.home.subTitle'))

  // 背景颜色
  const bgImg = computed(() => {
    const url = (isMobile.value ? config.value?.bgUrl : config.value?.pcBgUrl)
    return url
  })

  return {
    config,
    bgImg,
    pageTitle,
    subTitle,
    listRoutePath,
  }
}
